import React from "react";
import CommonTitle from '@/components/CommonTitle';
import { ALL_NAV_LIST } from '@/config/index.js';
import * as styles from './index.module.scss';

const Introduction = (props) => {
  const { id } = props || {};
  return (
    <div>
      <CommonTitle title={ALL_NAV_LIST.find(item => item.id === +id)?.title} />
      <div className={styles.introduction}>
        {id === 10002 && <>
          <p>
            &nbsp; &nbsp; &nbsp; &nbsp;衡水仲裁委员会调解中心是经衡水市司法局批准，在衡水市民政局注册登记，受中国人民银行衡水市中心支行监督与指导，是衡水市月湖区人民法院特邀的调解组织。<br />
            &nbsp; &nbsp; &nbsp; &nbsp;本站发布之所有形式的作品，谢绝任何个人、组织任何形式的转载。凡是非本站首次发布的作品，转发人应在主贴标题之前注明［转贴］，或者在主贴内标注出处、原作者和整理修改的情况，网络转介应作说明，尽量给出源链接地址；如有可能，应事先与原作者或相关管理人员进行沟通。<br />&nbsp;
          </p>
        </>}
        {id === 10003 && <>
          &nbsp; &nbsp; &nbsp; &nbsp;衡水仲裁委员会调解中心是经衡水市司法局批准，在衡水市民政局注册登记，受中国人民银行衡水市中心支行监督与指导，是衡水市月湖区人民法院特邀的调解组织。<br />
          &nbsp; &nbsp; &nbsp; &nbsp;本站发布之所有形式的作品，谢绝任何个人、组织任何形式的转载。凡是非本站首次发布的作品，转发人应在主贴标题之前注明［转贴］，或者在主贴内标注出处、原作者和整理修改的情况，网络转介应作说明，尽量给出源链接地址；如有可能，应事先与原作者或相关管理人员进行沟通。
        </>}
        {id === 10004 && <>
          <p>
            &nbsp; &nbsp; &nbsp; &nbsp;衡水仲裁委员会调解中心是经衡水市司法局批准，在衡水市民政局注册登记，受中国人民银行衡水市中心支行监督与指导，是衡水市月湖区人民法院特邀的调解组织。<br />
            &nbsp; &nbsp; &nbsp; &nbsp;网址是：ytfdrc.com<br />&nbsp; &nbsp; &nbsp; &nbsp;地址：江西省衡水市　　<br />&nbsp; &nbsp; &nbsp; &nbsp;联系电话：(0701)6221965<br />
            &nbsp; &nbsp; &nbsp; &nbsp;欢迎大家积极访问并踊跃投稿。
          </p>
        </>}
        {id === 10005 && <>
          &nbsp; &nbsp; &nbsp; &nbsp;衡水仲裁委员会调解中心是经衡水市司法局批准，在衡水市民政局注册登记，受中国人民银行衡水市中心支行监督与指导，是衡水市月湖区人民法院特邀的调解组织。依法在案件登记立案前或者登记立案后，接受法院的委托，对案件进行调解。
        </>}
      </div>
    </div>
  )
}

export default React.memo(Introduction);