import React, { useState } from "react";
import { Button, Input, message, Form } from 'antd';
import { getVerifyCode } from '@/api/index.js';
import * as styles from './index.module.scss';

const { Search } = Input;


const CaseSearch = (props) => {

  const [formData, setFormData] = useState({});


  const goSearchDetail = () => {
    const { idcard, tel, verifyCode } = formData || {};
    if (!idcard) {
      message.warning({
        content: '请输入债务人证件号码/社会统一信用代码',
      });
      return;
    }
    if (!tel) {
      message.warning({
        content: '请输入手机号',
      });
      return;
    }
    if (!idcard) {

    }
    window.open(`/#/case-detail/${idcard}/${tel}/${verifyCode}`);
  }

  const handleRequestVerifyCode = () => {
    const { tel } = formData || {};
    getVerifyCode({
      tel,
    }).then(res => {
      message.info({
        content: res?.data,
        duration: 6,
      });
    })
  };

  const onFinish = () => {
    goSearchDetail();
  }

  return (
        <div className={styles.form_wrapper}>
          <Form
            className={styles.form}
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label=""
              name="idcard"
              rules={[{ required: true, message: '请输入证件号码/社会统一信用代码' }]}
            >
              <div className={styles.form_item}>
                <img className={styles.icon} src={require('@/assets/images/loginzhImg.png')} alt="" />
                <Input
                  className={styles.info_input}
                  placeholder="证件号码/社会统一信用代码"
                  value={formData.idcard}
                  onChange={e => {
                    setFormData({ ...formData, idcard: e.target.value })
                  }}
                />
              </div>
            </Form.Item>

            <Form.Item
              label=""
              name="tel"
              rules={[
                { required: true, message: '请输入手机号' },
                { pattern: /^1[0-9]{10}/, message: '请输入正确的手机号' },
              ]}
            >
              <div className={styles.form_item}>
                <img className={styles.icon} src={require('@/assets/images/loginsjImg.png')} alt="" />
                <Input
                  className={styles.info_input}
                  placeholder="请输入手机号"
                  value={formData.tel}
                  onChange={e => {
                    setFormData({ ...formData, tel: e.target.value })
                  }}
                />
              </div>
            </Form.Item>
            <Form.Item
              label=""
              name="verifyCode"
              rules={[{ required: true, message: '请输入验证码' }]}
            >
              <div className={styles.form_item}>
                <img className={styles.icon} src={require('@/assets/images/loginCodeImg.png')} alt="" />
                <Search
                  className={styles.info_input}
                  placeholder="请输入验证码"
                  allowClear
                  enterButton="发送验证码"
                  size="large"
                  onSearch={handleRequestVerifyCode}
                  onChange={e => {
                    setFormData({ ...formData, verifyCode: e.target.value })
                  }}
                />
              </div>
            </Form.Item>
            <Form.Item>
              <Button
                className={styles.search_btn}
                block
                color="primary"
                size="large"
                htmlType="submit"
              >查询</Button>
            </Form.Item>
          </Form>
        </div>
  )
}

export default React.memo(CaseSearch);