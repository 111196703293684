import React from "react";
import { Link } from 'react-router-dom';
import ALink from '@/components/Alink';
import * as styles from './index.module.scss';

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.foot_main}>
        <div className={styles.foot_left}>
          <ALink href="/">网站首页</ALink>
          | <Link to="/detail/10002">隐私声明</Link>
          | <Link to="/detail/10003">法律声明</Link>
          | <Link to="/detail/10004">使用帮助</Link>
          | <Link to="/detail/10005">关于我们</Link>
          <br />
          衡水仲裁委员会调解中心   备案许可证编号：
          <ALink href="http://beian.miit.gov.cn/" target="_blank">京ICP备2023009720号-1</ALink>
          <br />
          地址：河北省衡水市　　 联系电话：010-62416702　　 投诉电话：010-62416702<br />
        </div>
        <div className={styles.foot_right}><img src={require('@/assets/images/erweima.png')} alt="" /><br /><span>案件查询</span></div>
      </div>
    </div>
  )
}

export default React.memo(Footer);