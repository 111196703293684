import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Col, Row, Modal, Button ,Spin } from 'antd';
import { isEmpty } from 'lodash';
import CaseProgress from './CaseProgress';

import * as api from '@/api/index.js';

import * as styles from './index.module.scss';

const CaseDetail = (props) => {
  const [caseInfo, setCaseInfo] = useState({});

  const [previewInfo, setPreviewInfo] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handlePreview = (info) => {
    setPreviewInfo(info);
    showModal();
  }

  const handleDownload = (info) => {
    api.getCaseFilePath({ caseId: info.caseId }).then(res => {
      const { data } = res || {};
      if (!data) return;
      const fileName = data.split('/').pop();
      let link = document.createElement('a');
      link.style.display = 'none';
      link.href = data;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    })
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const { idcard, tel, verifyCode } = props?.match?.params || {};
    setLoading(true);
    api.searchCaseDetailByIdcard({
      idcard,
      tel,
      verifyCode,
    }).then(res => {
      setLoading(false);
      const { code, data } = res || {};
      if (+code === 10000) {
        setCaseInfo(data || {});
      }
    });
  }, []);

  return (
     <>
      {
        loading ? <div style={{width:"100%",height:"100vh",textAlign:"center",paddingTop:200}}>
        <Spin  size={"large"} spinning={loading} tip={"正在查询"} />
        </div> :
      isEmpty(caseInfo) ? (
        <div className={styles.hint}>无案件数据，请确认证件后后重新搜索.</div>
      ) : (
        <div className={styles.case_detail}>
      <div className={styles.case_overview}>
        <h3 className={styles.title}>案件总览</h3>
        <div className={styles.case_info}>
          <div>
            <span>姓名：</span>
            <span>{caseInfo.borrowerName}</span>
          </div>
          <div>
            <span>案件数量：</span>
            <span>{caseInfo.caseCount}</span>
          </div>
          <div>
            <span>欠款总额：</span>
            <span>{caseInfo.sumAmount}</span>
          </div>
        </div>
      </div>
      <div className={styles.case_list}>
        <h3 className={styles.title}>案件列表</h3>
        <div className={styles.list_wrapper}>
          {(caseInfo.caseList || []).map((item, index) => (
            <div className={styles.list_item} key={index}>
              <h4>案件进展</h4>
              <div className={styles.list_item_inner}>
                <CaseProgress caseStatus={item.caseStatus} />
              </div>

              <h4>案件信息</h4>
              <div className={styles.list_item_inner}>
                <Row>
                  <Col span={3}>案件id：</Col>
                  <Col span={9}>{item.caseId || '-'}</Col>
                  <Col span={3}>案件查验码:</Col>
                  <Col span={9}>{item.contractCode || '-'}</Col>
                </Row>
                <Row>
                  <Col span={3}>金调案号：</Col>
                  <Col span={9}>{item.borrowerIdcard || '-'}</Col>
                  <Col span={3}>被告姓名：</Col>
                  <Col span={9}>{item.borrowerName || '-'}</Col>
                </Row>
                <Row>
                  <Col span={3}>诉讼金额：</Col>
                  <Col span={9}>{item.originMoney || '-'}</Col>
                  <Col span={3}>调解函：</Col>
                  <Col span={9}>
                    <a className={styles.preview_btn} target="_blank" href={'/file/' + item.pdfPath.substr(21) + '/' + item.caseId + '.pdf'}>预览</a>
                    <a className={styles.download_btn} target="_blank" download={item.caseId + '.pdf'} href={'/file/' + item.pdfPath.substr(21) + '/' + item.caseId + '.pdf'}>下载</a>
                  </Col>
                </Row>
              </div>
              <h4>调解员信息</h4>
              <div className={styles.list_item_inner}>
                <Row>
                  <Col span={3}>调解员：</Col>
                  <Col span={9}>{item.userName || '-'}</Col>
                  <Col span={3}>调解员座机号：</Col>
                  <Col span={9}>{item.userTel || '-'}</Col>
                </Row>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        title="预览"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            关闭
          </Button>
        ]}
      >
        <div className={styles.agreement_content}>
          <h2>调解协议书</h2>
          <p>编号：<font>{previewInfo.caseId}</font></p>
          <p>申请人：<font>{previewInfo.clientName}</font></p>
          <p>法人：<font>{previewInfo.clientName}</font></p>
          <div className={styles.empty_block} />
          <p>被申请人：<font>{previewInfo.borrowerName}</font></p>
          <p>身份证号：<font>{previewInfo.borrowerIdcard}</font></p>
          <p>联系方式：<font>{previewInfo.borrowerTel}</font></p>
          <p>住址：<font>{previewInfo.addrHome}</font></p>
          <div className={styles.empty_block} />
          <p>申请人 <font>{previewInfo.clientName}</font> 与被申请人 <font>{previewInfo.borrowerName}</font> 借款合同纠纷一案，将争议交由衡水仲裁委员会调解中心，雅衡水仲裁委员会调解中心受理后，适用《中华人民共和国人民调解法》的规定启动调解程序，双方当事人自愿达成如下调解协议，以资共同遵守。</p>
          <h3>一、借款信息</h3>
          <p>被申请人：<font>{previewInfo.borrowerName}</font>;  身份证：<font>{previewInfo.borrowerIdcard}</font></p>
          <p>借款人借款总金额(本金)： 人民币（大写）： <font>{previewInfo.originMoneyUp}</font> 小写：<font>{previewInfo.originMoney}</font>;</p>
          <p>首期还款金额：人民币（大写）： <font>{previewInfo.repayMoneyPeriodUp}</font> 小写：<font>{previewInfo.repayMoneyPeriod}</font>;</p>
          <p>最后一期还款金额：人民币（大写）： <font>{previewInfo.repayMoneyPeriodUp}</font> 小写：<font>{previewInfo.repayMoneyPeriod}</font>;</p>
          <p>还款期数：<font>{previewInfo.repayPeriodTotal}</font>;借款期限：<font>{previewInfo.contractPeriodCount}</font>（单位：月） ;</p>
          <h3>二、债权详情</h3>
          <p>上述债权因被申请人 <font>{previewInfo.borrowerName}</font> 资金困难，无力偿还一直拖欠至今。现根据《中华人民共和国民法典》及相关信贷借款协议的约定，原债权人 <font>{previewInfo.clientName}</font> 已依法将享有的对被申请人 <font>{previewInfo.borrowerName}</font> 的上述债权共计人民币 <font>{previewInfo.originMoney}</font> 元（包含本金及相应息费等）转让给申请人 <font>{previewInfo.clientName}</font> ,与此转让债权的其他权利也一并转让，债权转让后，原债权人依法通知被申请人债权已经转让，此后将由被申请人 <font>{previewInfo.borrowerName}</font> 向申请人 <font>{previewInfo.clientName}</font> 履行还款义务。</p>
          <h3>三、双方一致同意（方案）</h3>
          <p>基于上述事实，经衡水仲裁委员会调解中心调解，双方就还款达成如下协议：被申请人 <font>{previewInfo.borrowerName}</font> 自愿于 <font>{previewInfo.contractPeriodCount}</font> 一次性归还申请人万联征信管理有限公司减免后还款总金额： <font>{previewInfo.originMoney}</font> 元。</p>
          <p>上述款项由被申请人 <font>{previewInfo.borrowerName}</font> 支付至申请人 <font>{previewInfo.clientName}</font>  的以下账户：</p>
          <p>企业银行对公账户：</p>
          <p className={styles.red_bg}>
            <span>【开户名称：<font>{previewInfo.accountName}</font>】</span></p>
          <p className={styles.red_bg}>
            <span>【账号：<font>{previewInfo.bankCard}</font>】</span>
          </p>
          <p className={styles.red_bg}>
            <span>【开户银行：<font>{previewInfo.bankName}</font>】</span>
          </p>
          <h3>四、经双方同意，任何一方未按约定履行义务的，均可选择向原告或者调解组织所在地人民法院提起诉讼； </h3>
          <h3>五、本协议一式两份，双方当事人、调解中心各持一份；</h3>
          <h3>上述协议经我调解中心审查，予以确认。</h3>
          <p>本调解协议书由双方当事人签名或者盖章，经调解会议确认后生效，对双方当事人具有约束力，当事人应当履行。</p>
          <div className={styles.footer}>
            <p>申请人：<font>{previewInfo.clientName}</font></p>
            <p>（印章）</p>
            <p>（以下为当事人签字区域）</p>
            <p>被申请人签字：</p>
          </div>
        </div>
      </Modal>
    </div>
      )
    }
    </>
  )
}

export default React.memo(withRouter(CaseDetail));