import React, { useState } from "react";
import { Button, Input, Toast } from 'antd-mobile';
import { getVerifyCode } from '@/api/index.js';

import * as styles from './index.module.scss';


const CaseSearch = (props) => {
  const [formData, setFormData] = useState({});

  const goSearchDetail = () => {
    const { idcard, tel, verifyCode } = formData || {};
    if (!idcard) {
      Toast.show({
        content: '请输入债务人证件号码/社会统一信用代码',
      });
      return;
    }
    if (!tel) {
      Toast.show({
        content: '请输入手机号',
      });
      return;
    }
    if (!idcard) {

    }
    props.history.push({
      pathname: `/case-detail-mobile/${idcard}/${tel}/${verifyCode}`,
    });
  }

  const handleRequestVerifyCode = () => {
    const { tel } = formData || {};
    if (!tel) {
      Toast.show({
        content: '请输入手机号',
      });
      return;
    }
    getVerifyCode({
      tel,
    }).then(res => {
      Toast.show({
        content: res?.data,
        duration: 6000,
      });
    })
  };

  return (
    <div className={styles.case_search}>
      <div className={styles.img_wrapper}>
        <img className={styles.logo} src={require('@/assets/images/loginImg.png')} alt="" />
      </div>
      <div className={styles.title}>
        衡水仲裁委员会调解中心
      </div>
      <div className={styles.form_wrapper}>
        <div className={styles.form_item}>
          <img className={styles.icon} src={require('@/assets/images/loginzhImg.png')} alt="" />
          <Input
            className={styles.info_input}
            placeholder="证件号码/社会统一信用代码"
            value={formData.idcard}
            onChange={val => {
              setFormData({ ...formData, idcard: val })
            }}
          />
        </div>
        <div className={styles.form_item}>
          <img className={styles.icon} src={require('@/assets/images/loginsjImg.png')} alt="" />
          <Input
            className={styles.info_input}
            placeholder="请输入手机号"
            value={formData.tel}
            onChange={val => {
              setFormData({ ...formData, tel: val })
            }}
          />
        </div>
        <div className={styles.form_item}>
          <img className={styles.icon} src={require('@/assets/images/loginCodeImg.png')} alt="" />
          <div className={styles.verifycode_wrapper}>
            <Input
              className={styles.info_input}
              placeholder="请输入验证码"
              value={formData.verifyCode}
              onChange={val => {
                setFormData({ ...formData, verifyCode: val })
              }}
            />
            <span className={styles.verifycode_btn} onClick={handleRequestVerifyCode}>发送验证码</span>
          </div>
        </div>
        <Button
          className={styles.search_btn}
          block
          color='primary'
          size='large'
          onClick={goSearchDetail}
        >查询</Button>
      </div>
    </div>
  )
}

export default React.memo(CaseSearch);